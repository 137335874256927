import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Edit } from '@mui/icons-material';
import Header from '../../../components/Header';
import { useTheme } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../axios';
import toast from 'react-hot-toast';
import { Formik } from 'formik';
import * as yup from 'yup';
import CustomGridToolbar from '../../../components/CustomGridToolbar';

const Speeches = () => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [selectedSpeechId, setSelectedSpeechId] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedSpeech, setEditedSpeech] = useState({
    id: '',
    title: '',
    image: '',
    date: new Date(),
    description: '',
  });

  const handleCloseDialog = () => {
    setOpenConfirmationDialog(false);
    setOpenEditDialog(false);
    setEditedSpeech({
      id: '',
      title: '',
      image: '',
      date: new Date(),
      description: '',
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/speeches/${selectedSpeechId}`);
      setOpenConfirmationDialog(false);
      await queryClient.refetchQueries('speeches');
    } catch (error) {
      console.error('Error deleting speech:', error);
    }
  };

  const handleDelete = (id) => {
    setSelectedSpeechId(id);
    setOpenConfirmationDialog(true);
  };

  const handleEdit = (id, title, image, date, description) => {
    setEditedSpeech({
      id,
      title,
      image,
      date,
      description,
    });
    setOpenEditDialog(true);
  };

  const handleFormSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      if (values.image) {
        formData.append('image', values.image);
      }
      formData.append('description', values.description);
      await api.put(`/speeches/${editedSpeech.id}`, formData);
      setEditedSpeech({
        id: '',
        title: '',
        image: '',
        date: new Date(),
        description: '',
      });
      queryClient.invalidateQueries('speeches');
      handleCloseDialog();
      toast.success('Speech updated successfully');
    } catch (error) {
      console.error('Error updating speech:', error);
      toast.error(error?.response?.data?.msg || 'Failed to update speech');
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 1 },
    {
      field: 'image',
      headerName: 'Image',
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt=""
          style={{ width: '40px', height: 'auto' }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteOutline />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() =>
              handleEdit(
                params.row.id,
                params.row.title,
                params.row.image,
                params.row.date,
                params.row.description
              )
            }
          >
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const speechSchema = yup.object().shape({
    title: yup.string().min(3).required('Title is required'),
    image: yup.mixed(),
    description: yup.string().required('Description is required'),
  });

  const {
    data: speeches,
    isLoading,
    isError,
  } = useQuery('speeches', async () => {
    const response = await api.get('/speeches?limit=1000');
    return response.data.speeches;
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  return (
    <Box m="20px">
      <Header
        title="Speeches"
        subtitle="List of Speeches for Future Reference"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
        }}
      >
        <DataGrid
          rows={speeches.map((speech) => ({
            id: speech._id,
            title: speech.title,
            date: new Date(speech.date).toLocaleDateString(),
            image: speech.image.secure_url,
            description: speech.description,
          }))}
          columns={columns}
          components={{ Toolbar: CustomGridToolbar }}
        />
      </Box>
      <Dialog open={openConfirmationDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Speech</DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to remove this speech?</Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={{ backgroundColor: theme.palette.primary.main }}
          >
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Speech</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              title: editedSpeech.title,
              image: null,
              description: editedSpeech.description,
            }}
            validationSchema={speechSchema}
            onSubmit={(values) => handleFormSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  label="Image"
                  name="image"
                  onChange={(e) => setFieldValue('image', e.target.files[0])}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description"
                  name="description"
                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  margin="normal"
                />
                <DialogActions>
                  <Button onClick={handleCloseDialog} variant="contained">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Speeches;
