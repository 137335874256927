import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Edit } from '@mui/icons-material';
import Header from '../../../components/Header';
import { useTheme } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../axios';
import toast from 'react-hot-toast';
import { Formik } from 'formik';
import * as yup from 'yup';
import CustomGridToolbar from './../../../components/CustomGridToolbar';

const Colleges = () => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [selectedCollegeId, setSelectedCollegeId] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedCollege, setEditedCollege] = useState({
    id: '',
    name: '',
    image: '',
  });

  const handleCloseDialog = () => {
    setOpenConfirmationDialog(false);
    setOpenEditDialog(false);
    setEditedCollege({
      id: '',
      name: '',
      image: '',
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/college/${selectedCollegeId}`);
      setOpenConfirmationDialog(false);
      await queryClient.refetchQueries('colleges');
    } catch (error) {
      console.error('Error deleting college:', error);
    }
  };

  const handleDelete = (id) => {
    setSelectedCollegeId(id);
    setOpenConfirmationDialog(true);
  };

  const handleEdit = (id, name, image) => {
    setEditedCollege({
      id,
      name,
      image,
    });
    setOpenEditDialog(true);
  };

  const handleFormSubmit = async (values) => {
    if (!values.name) {
      toast.error('Name is required');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      if (values.image !== null && values.image !== undefined) {
        formData.append('image', values.image);
      }
      await api.put(`/college/${editedCollege.id}`, formData);
      setEditedCollege({
        id: '',
        name: '',
        image: '',
      });
      queryClient.invalidateQueries('colleges');
      handleCloseDialog();
      toast.success('College updated successfully');
    } catch (error) {
      console.error('Error updating college:', error);
      toast.error(error?.response?.data?.msg || 'Failed to update college');
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'image',
      headerName: 'Image',
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt=""
          style={{ width: '40px', height: 'auto' }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteOutline />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() =>
              handleEdit(params.row.id, params.row.name, params.row.image)
            }
          >
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const collegeSchema = yup.object().shape({
    name: yup.string().min(2).required('Name is required'),
    image: yup.mixed(),
  });

  const {
    data: colleges,
    isLoading,
    isError,
  } = useQuery('colleges', async () => {
    const response = await api.get('/college?limit=1000');
    return response.data.college;
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  return (
    <Box m="20px">
      <Header
        title="Colleges"
        subtitle="List of Colleges for Future Reference"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
        }}
      >
        <DataGrid
          rows={colleges.map((college) => ({
            id: college._id,
            name: college.name,
            image: college.image.secure_url,
          }))}
          columns={columns}
          components={{ Toolbar: CustomGridToolbar }}
        />
      </Box>
      <Dialog open={openConfirmationDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete College</DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to remove this college?</Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={{ backgroundColor: theme.palette.primary.main }}
          >
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit College</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: editedCollege.name,
              image: null,
            }}
            validationSchema={collegeSchema}
            onSubmit={(values) => handleFormSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  label="Image"
                  name="image"
                  onChange={(e) => setFieldValue('image', e.target.files[0])}
                  margin="normal"
                />
                <DialogActions>
                  <Button onClick={handleCloseDialog} variant="contained">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Colleges;
