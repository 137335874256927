import { Box, Button, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/Header';
import api from '../../axios';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const OTPLogin = () => {
  const { setAuth } = useAuth();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();
  const { email } = useParams();

  const [timer, setTimer] = useState(120);
  const [otpCode, setOtpCode] = useState('');
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(timerInterval);
        setExpired(true);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timer]);

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(email, otpCode)
      const response = await api.post('/auth/otp-signin', { email, otpCode });
      if (response) {
        setAuth({ token: response.data.token });
        // Set cookies and navigate upon successful OTP verification
        const oneHourFromNow = new Date();
        oneHourFromNow.setTime(oneHourFromNow.getTime() + 60 * 60 * 1000); // One hour in milliseconds
        setCookie('authToken', response.data.token, {
          maxAge: 3600, // One hour in seconds
          expires: oneHourFromNow,
        });
        navigate('/kutlas');
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.msg || 'Invalid OTP code');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box
        m="20px"
        p="20px"
        width={{ xs: '100%', sm: '50%', md: '40%' }}
        borderRadius="10px"
      >
        <Header title="OTP Login" subtitle="Enter OTP Code" />

        <Formik
          initialValues={{ email: '', otpCode: '' }}
          validationSchema={otpSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <form onSubmit={handleFormSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              >
                <Typography variant="body2" color="textSecondary">
                  Please check your email ({email}) for the OTP code. You have{' '}
                  <span style={{ color: 'red', fontSize: '20px' }}>
                    {timer}
                  </span>{' '}
                  seconds to enter the code.
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="OTP Code"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setOtpCode(e.target.value);
                    handleChange(e);
                  }}
                  value={values.otp}
                  name="otp"
                  required // Add required attribute
                  error={touched.otpCode && !!errors.otpCode}
                  helperText={touched.otpCode && errors.otpCode}
                />
              </Box>
              <Box display="flex" justifyContent="space-between" mt="20px">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={expired}
                >
                  Verify OTP
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        {expired && (
          <Typography variant="body2" color="error">
            OTP expired. Please request a new one.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const otpSchema = yup.object().shape({
  otpCode: yup.string().required('Required'),
  email: yup.string().email('Invalid email address').required('Required'),
});

export default OTPLogin;
