import { useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Topbar from '../scenes/global/Topbar';
import Sidebar from '../scenes/global/Sidebar';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from '../theme';

function Layout() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  const isProtectedRoute = location.pathname.startsWith('/auth');
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* Render sidebar only if not on login page */}
          {!isProtectedRoute && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {/* Render topbar only if not on login page */}
            {!isProtectedRoute && <Topbar setIsSidebar={setIsSidebar} />}
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Layout;
