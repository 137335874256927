import React, { useState } from 'react';
import { Box, Button, Typography, IconButton, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { DeleteOutline } from '@mui/icons-material';
import api from '../../../axios';
import { useQueryClient } from 'react-query';
import { DataGrid } from '@mui/x-data-grid';

const CoursePage = () => {
  const { id } = useParams();
  const { data: courseData, isLoading: courseLoading } = useQuery(
    ['course', id],
    async () => {
      const response = await api.get(`/course/${id}`);
      return response.data.course;
    }
  );
  const queryClient = useQueryClient();
  const theme = useTheme();

  const [showSummaries, setShowSummaries] = useState(false);
  const [uploadType, setUploadType] = useState('file'); // 'file' or 'link'

  const handleUploadTypeChange = (type) => {
    setUploadType(type);
  };

  const handleAddSummary = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      if (uploadType === 'file') {
        formData.append('summary', values.file);
      } else {
        if (values.fileName) formData.append('fileName', values.fileName);
        if (values.fileUrl) formData.append('fileUrl', values.fileUrl);
      }
      formData.append('courseId', id);
      await api.post('/summary', formData);
      resetForm();
      toast.success('Summary added successfully');
      queryClient.invalidateQueries(['course', id]);
    } catch (error) {
      toast.error('Failed to add summary');
    }
  };

  const handleDeleteSummary = async (summaryId) => {
    try {
      await api.delete(`/summary/${summaryId}`);
      toast.success('Summary deleted successfully');
      queryClient.invalidateQueries(['course', id]);
    } catch (error) {
      toast.error('Failed to delete summary');
    }
  };

  const summarySchema = yup.object().shape({
    file: yup.mixed().when('uploadType', {
      is: 'file',
      then: yup.mixed(),
    }),
    fileName: yup.string().when('uploadType', {
      is: 'link',
      then: yup.string(),
    }),
    fileUrl: yup.string().when('uploadType', {
      is: 'link',
      then: yup.string().url('Invalid URL'),
    }),
  });

  if (courseLoading) return <div>Loading...</div>;

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'file',
      headerName: 'File',
      flex: 1,
      renderCell: (params) => (
        <Button
          href={params.value?.secure_url}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          variant="contained"
        >
          Download
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteSummary(params.row.id)}
        >
          <DeleteOutline />
        </IconButton>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Typography variant="h2" gutterBottom>
        {courseData.name?.toUpperCase()}
      </Typography>
      {!showSummaries && (
        <Box mb={2} style={{ maxWidth: '300px' }}>
          <img
            src={courseData.image?.secure_url}
            alt={courseData.name}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
      )}
      {!showSummaries && (
        <Box mt={4} mb={4}>
          <Typography variant="h5" gutterBottom>
            Add Summary
          </Typography>
          <Box mb={2}>
            <Button
              variant={uploadType === 'file' ? 'contained' : 'outlined'}
              onClick={() => handleUploadTypeChange('file')}
              style={{
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#333' : '#fff',
              }}
            >
              Upload File
            </Button>
            <Button
              variant={uploadType === 'link' ? 'contained' : 'outlined'}
              onClick={() => handleUploadTypeChange('link')}
              style={{
                marginLeft: '10px',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#333' : '#fff',
              }}
            >
              Provide Link
            </Button>
          </Box>
          <Formik
            initialValues={{
              file: null,
              fileName: '',
              fileUrl: '',
            }}
            validationSchema={summarySchema}
            onSubmit={handleAddSummary}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {uploadType === 'file' && (
                  <div>
                    <input
                      id="file"
                      name="file"
                      type="file"
                      onChange={(event) => {
                        setFieldValue('file', event.currentTarget.files[0]);
                      }}
                    />
                    {touched.file && errors.file && (
                      <div style={{ color: 'red' }}>{errors.file}</div>
                    )}
                  </div>
                )}
                {uploadType === 'link' && (
                  <div>
                    <input
                      id="fileName"
                      name="fileName"
                      type="text"
                      placeholder="File Name"
                      onChange={(event) => {
                        setFieldValue('fileName', event.target.value);
                      }}
                    />
                    {touched.fileName && errors.fileName && (
                      <div style={{ color: 'red' }}>{errors.fileName}</div>
                    )}
                    <input
                      id="fileUrl"
                      name="fileUrl"
                      type="text"
                      placeholder="File URL"
                      onChange={(event) => {
                        setFieldValue('fileUrl', event.target.value);
                      }}
                    />
                    {touched.fileUrl && errors.fileUrl && (
                      <div style={{ color: 'red' }}>{errors.fileUrl}</div>
                    )}
                  </div>
                )}
                <Box mt={2}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      backgroundColor: '#1976d2',
                    }}
                    disabled={isSubmitting}
                  >
                    Add Summary
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}

      {showSummaries ? (
        <Button
          style={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            backgroundColor: '#1976d2',
          }}
          onClick={() => setShowSummaries(false)}
        >
          Hide Summaries
        </Button>
      ) : (
        <Button
          style={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            backgroundColor: '#1976d2',
          }}
          onClick={() => setShowSummaries(true)}
        >
          Show Summaries
        </Button>
      )}

      {showSummaries && (
        <Box m="40px 0 0 0" height="75vh">
          <DataGrid
            rows={courseData.Summary.map((summary) => ({
              id: summary._id,
              name: summary.name,
              file: summary.file,
            }))}
            columns={columns}
          />
        </Box>
      )}
    </Box>
  );
};

export default CoursePage;
