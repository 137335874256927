import { Box, Button, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/Header';
import { toast } from 'react-hot-toast';
import api from '../../axios';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  const handleEmailSubmit = async () => {
    try {
      const schema = yup.object().shape({
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),
      });
      await schema.validate({ email });
      const response = await api.patch('/auth/send-code', { email });
      if (response.status === 200) {
        setEmailValid(true);
      }
    } catch (error) {
      toast.error('Email not found');
      setEmailError(error.message);
      setEmailValid(false);
    }
  };

  const handleResetPasswordSubmit = async (values) => {
    try {
      const response = await api.patch('/auth/forgot-password', {
        email,
        code: values.code,
        password: values.password,
      });
      if (response.status === 200) {
        toast.success('Password reset successful');
        navigate('/auth/login');
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box
        m="20px"
        p="20px"
        width={{ xs: '100%', sm: '50%', md: '40%' }}
        borderRadius="10px"
      >
        <Header title="RESET PASSWORD" subtitle="Reset Your Password" />

        {!emailValid ? (
          <Box>
            <Typography variant="body2" color="textSecondary" mb={2}>
              Enter your email to receive a code
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError || ''}
              mb={2}
            />
            <Button
              variant="contained"
              mt={2}
              onClick={handleEmailSubmit}
              size="large"
            >
              Submit
            </Button>
          </Box>
        ) : (
          <Formik
            initialValues={{ code: '', password: '', confirmPassword: '' }}
            validationSchema={resetPasswordSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                handleResetPasswordSubmit(values);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Code"
                    name="code"
                    value={values.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.code && errors.code}
                    helperText={touched.code && errors.code}
                    mb={2}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="New Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                    mb={2}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.confirmPassword && errors.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    mb={2}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    mt={2}
                    color="primary"
                    size="large"
                    disabled={isSubmitting}
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        )}
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            <Link to="/auth/login">Back to login</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

// Schema for password reset form validation
const resetPasswordSchema = yup.object().shape({
  code: yup.string().required('Code is required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

export default ResetPassword;
