import { Box, Button, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import api from '../../../axios';

const CreateNewsForm = () => {
  const [image, setImage] = useState(null);

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('image', values.image);
      formData.append('description', values.description);

      const response = await api.post('/news', formData);
      resetForm();
      setImage(null);
      toast.success('News item created successfully');
    } catch (error) {
      console.error('Error creating news item:');
      toast.error(error?.response?.data?.msg || 'Failed to create news item');
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        Create News Item
      </Typography>

      {/* Form */}
      <Formik
        initialValues={{
          title: '',
          image: null,
          description: '',
        }}
        validationSchema={newsSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Title"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.title && !!errors.title}
              helperText={touched.title && errors.title}
              margin="normal"
            />

            {/* Image Upload */}
            <input
              id="image"
              name="image"
              type="file"
              onChange={(event) => {
                setFieldValue('image', event.currentTarget.files[0]);
                setImage(URL.createObjectURL(event.currentTarget.files[0]));
              }}
            />
            {image && <img src={image} alt="NewsImage" width="200" />}

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Description"
              name="description"
              multiline
              rows={4}
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
              margin="normal"
            />

            {/* Submit Button */}
            <Box mt={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                Create News
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Validation schema for the news form
const newsSchema = yup.object().shape({
  title: yup.string().min(3).required('Title is required'),
  image: yup.mixed().required('Image is required'),
  description: yup.string().required('Description is required'),
});

export default CreateNewsForm;
