import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import api from '../../../axios';

const CreateMajorForm = () => {
  const [image, setImage] = useState(null);

  // Fetch colleges
  const {
    data: collegesData,
    isLoading: collegesLoading,
    isError: collegesError,
  } = useQuery('colleges', async () => {
    const response = await api.get('/college?limit=1000');
    return response.data.college;
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('collegeId', values.collegeId);
      formData.append('image', values.image);
      const response = await api.post('/major', formData);
      resetForm();
      setImage(null);
      toast.success('Major created successfully');
    } catch (error) {
      console.error('Error creating major:');
      toast.error(error?.response?.data?.msg || 'Failed to create major');
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        Create Major
      </Typography>

      {/* Form */}
      <Formik
        initialValues={{
          name: '',
          collegeId: '',
          image: null,
        }}
        validationSchema={majorSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              margin="normal"
            />

            {/* Select College */}
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel id="collegeId-label">College</InputLabel>
              <Select
                labelId="collegeId-label"
                id="collegeId"
                name="collegeId"
                value={values.collegeId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.collegeId && !!errors.collegeId}
              >
                {collegesLoading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : collegesError ? (
                  <MenuItem disabled>Error loading colleges</MenuItem>
                ) : (
                  collegesData.map((college) => (
                    <MenuItem key={college.id} value={college.id}>
                      {college.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            {/* Image Upload */}
            <input
              id="image"
              name="image"
              type="file"
              onChange={(event) => {
                setFieldValue('image', event.currentTarget.files[0]);
                setImage(URL.createObjectURL(event.currentTarget.files[0]));
              }}
            />
            {image && <img src={image} alt="MajorImage" width="200" />}

            {/* Submit Button */}
            <Box mt={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                Create Major
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Validation schema for the major form
const majorSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  collegeId: yup
    .string()
    .required('College is required')
    .matches(/^[0-9a-fA-F]{24}$/, 'Invalid collegeId format'),
  image: yup.mixed().required('Image is required'),
});

export default CreateMajorForm;
