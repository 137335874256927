import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Edit } from '@mui/icons-material';
import Header from '../../../components/Header';
import { useTheme } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../axios';
import toast from 'react-hot-toast';
import { Formik } from 'formik';
import * as yup from 'yup';
import CustomGridToolbar from '../../../components/CustomGridToolbar';

const Majors = () => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [selectedMajorId, setSelectedMajorId] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedMajor, setEditedMajor] = useState({
    id: '',
    name: '',
    image: '',
    collegeId: '',
  });
  // Fetch colleges
  const {
    data: collegesData,
    isLoading: collegesLoading,
    isError: collegesError,
  } = useQuery('colleges', async () => {
    const response = await api.get('/college?limit=1000');
    return response.data.college;
  });

  const handleCloseDialog = () => {
    setOpenConfirmationDialog(false);
    setOpenEditDialog(false);
    setEditedMajor({
      id: '',
      name: '',
      image: '',
      collegeId: '',
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/major/${selectedMajorId}`);
      setOpenConfirmationDialog(false);
      await queryClient.refetchQueries('majors');
    } catch (error) {
      toast.error(error?.response?.data?.msg || 'Error deleting major');
    }
  };

  const handleDelete = (id) => {
    setSelectedMajorId(id);
    setOpenConfirmationDialog(true);
  };

  const handleEdit = (id, name, image, collegeId) => {
    setEditedMajor({
      id,
      name,
      image,
      collegeId,
    });
    setOpenEditDialog(true);
  };

  const handleFormSubmit = async (values) => {
    if (!values.name || !values.collegeId) {
      toast.error('Name and College are required');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      if (values.image) formData.append('image', values.image);
      formData.append('collegeId', values.collegeId);
      await api.put(`/major/${editedMajor.id}`, formData);
      setEditedMajor({
        id: '',
        name: '',
        image: '',
        collegeId: '',
      });
      queryClient.invalidateQueries('majors');
      handleCloseDialog();
      toast.success('Major updated successfully');
    } catch (error) {
      toast.error(error?.response?.data?.msg || 'Failed to update major');
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'collegeName', headerName: 'College', flex: 1 },
    {
      field: 'image',
      headerName: 'Image',
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt=""
          style={{ width: '40px', height: 'auto' }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteOutline />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() =>
              handleEdit(
                params.row.id,
                params.row.name,
                params.row.image,
                params.row.collegeId
              )
            }
          >
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const majorSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    image: yup.mixed(),
    collegeId: yup
      .string()
      .required('College is required')
      .matches(/^[0-9a-fA-F]{24}$/, 'Invalid collegeId format'),
  });

  const {
    data: majors,
    isLoading: majorsLoading,
    isError: majorsError,
  } = useQuery('majors', async () => {
    const response = await api.get('/major?limit=1000');
    return response.data.major;
  });
  const getCollegeNameById = (collegeId) => {
    const college = collegesData?.find((college) => college._id === collegeId);
    return college ? college.name : 'no college';
  };
  if (collegesLoading || majorsLoading) return <div>Loading...</div>;
  if (collegesError || majorsError) return <div>Error fetching data</div>;

  return (
    <Box m="20px">
      <Header title="Majors" subtitle="List of Majors for Future Reference" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
        }}
      >
        <DataGrid
          rows={majors.map((major) => ({
            id: major._id,
            name: major.name,
            image: major.image.secure_url,
            collegeId: major.collegeId,
            collegeName: getCollegeNameById(major.collegeId),
          }))}
          columns={columns}
          components={{ Toolbar: CustomGridToolbar }}
        />
      </Box>
      <Dialog open={openConfirmationDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Major</DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to remove this major?</Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={{ backgroundColor: theme.palette.primary.main }}
          >
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Major</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: editedMajor.name,
              image: null,
              collegeId: editedMajor.collegeId,
            }}
            validationSchema={majorSchema}
            onSubmit={(values) => handleFormSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  margin="normal"
                />
                <TextField
                  select
                  fullWidth
                  variant="filled"
                  label="College"
                  name="collegeId"
                  value={values.collegeId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.collegeId && !!errors.collegeId}
                  helperText={touched.collegeId && errors.collegeId}
                  margin="normal"
                >
                  {collegesData.map((college) => (
                    <MenuItem key={college._id} value={college._id}>
                      {college.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  label="Image"
                  name="image"
                  onChange={(e) => setFieldValue('image', e.target.files[0])}
                  margin="normal"
                />
                <DialogActions>
                  <Button onClick={handleCloseDialog} variant="contained">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Majors;
