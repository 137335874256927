import { Box, Button, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import Header from '../../components/Header';
import api from '../../axios';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

const LoginForm = () => {
  const { setAuth } = useAuth();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.authToken) {
      navigate('/kutlas');
    }
  }, [cookies.authToken, navigate]);

  const handleFormSubmit = async (values) => {
    try {
      const response = await api.post('/auth/signin', values);
      if (response.data.msg === 'Success') {
        navigate(`/auth/otp-login/${values.email}`);
      }
    } catch (error) {
      toast.error('User not found');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box
        m="20px"
        p="20px"
        width={{ xs: '100%', sm: '50%', md: '40%' }}
        borderRadius="10px"
      >
        <Header title="LOGIN" subtitle="Sign In to Your Account" />

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="primary" variant="contained">
                  Sign In
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            Forgot your password?{' '}
            <Link to="/auth/reset-password">Reset it here</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const loginSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
  password: yup.string().required('Required'),
});

const initialValues = {
  email: '',
  password: '',
};

export default LoginForm;
