import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import api from '../../../axios';

const CreateCourseForm = () => {
  const [image, setImage] = useState(null);

  // Fetch colleges
  const {
    data: collegesData,
    isLoading: collegesLoading,
    isError: collegesError,
  } = useQuery('colleges', async () => {
    const response = await api.get('/college?limit=1000');
    return response.data.college;
  });

  // Fetch majors
  const {
    data: majorsData,
    isLoading: majorsLoading,
    isError: majorsError,
  } = useQuery('majors', async () => {
    const response = await api.get('/major?limit=1000');
    return response.data.major;
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      if (values.image) formData.append('image', values.image);

      // Append each majorId as a separate object field
      values.majors.forEach((major, index) => {
        formData.append(`majorIds[${index}][id]`, major.id);
        formData.append(`majorIds[${index}][majorIndex]`, major.majorIndex);
      });

      // Log payload before sending the request
      console.log('Payload to be sent:', {
        name: values.name,
        image: values.image,
        majorIds: values.majors,
      });

      // Sending API request
      const response = await api.post('/course', formData);

      resetForm();
      setImage(null);
      toast.success('Course created successfully');
    } catch (error) {
      console.error('Error creating course:', error);
      toast.error(error?.response?.data?.msg || 'Failed to create course');
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        Create Course
      </Typography>

      {/* Form */}
      <Formik
        initialValues={{
          name: '',
          image: null,
          collegeIds: [],
          majors: [], // Now includes both ID and majorIndex
        }}
        validationSchema={courseSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              margin="normal"
            />

            {/* Image Upload */}
            <input
              id="image"
              name="image"
              type="file"
              onChange={(event) => {
                setFieldValue('image', event.currentTarget.files[0]);
                setImage(URL.createObjectURL(event.currentTarget.files[0]));
              }}
            />
            {image && <img src={image} alt="CourseImage" width="200" />}

            {/* Select Colleges */}
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel id="collegeIds-label">Colleges</InputLabel>
              <Select
                labelId="collegeIds-label"
                id="collegeIds"
                name="collegeIds"
                multiple
                value={values.collegeIds}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.collegeIds && !!errors.collegeIds}
              >
                {collegesLoading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : collegesError ? (
                  <MenuItem disabled>Error loading colleges</MenuItem>
                ) : (
                  collegesData.map((college) => (
                    <MenuItem key={college.id} value={college.id}>
                      {college.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>

            {/* Select Majors */}
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel id="majorIds-label">Majors</InputLabel>
              <Select
                labelId="majorIds-label"
                id="majorIds"
                name="majors"
                multiple
                value={values.majors.map((major) => major.id)} // Display major IDs as selected
                onChange={(event) => {
                  const selectedIds = event.target.value;
                  const selectedMajors = selectedIds.map((id) => {
                    const existingMajor = values.majors.find(
                      (m) => m.id === id
                    );
                    return existingMajor || { id, majorIndex: '' };
                  });
                  setFieldValue('majors', selectedMajors);
                }}
                onBlur={handleBlur}
                error={touched.majors && !!errors.majors}
                renderValue={(selected) =>
                  selected
                    .map((id) => {
                      const major = majorsData.find((major) => major.id === id);
                      return major ? major.name : '';
                    })
                    .join(', ')
                }
              >
                {majorsLoading ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : majorsError ? (
                  <MenuItem disabled>Error loading majors</MenuItem>
                ) : (
                  majorsData
                    .filter((major) =>
                      values.collegeIds.includes(major.collegeId)
                    )
                    .map((major) => (
                      <MenuItem key={major.id} value={major.id}>
                        {major.name}
                      </MenuItem>
                    ))
                )}
              </Select>
            </FormControl>

            {/* Input fields for each selected major's index */}
            {values.majors.map((major, index) => {
              const majorData = majorsData.find((m) => m.id === major.id);
              return (
                <Box key={major.id} mt={2}>
                  <Typography variant="body1">
                    {majorData ? majorData.name : 'Unknown Major'} Index:
                  </Typography>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    name={`majors[${index}].majorIndex`}
                    value={major.majorIndex}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.majors &&
                      touched.majors[index]?.majorIndex &&
                      !!errors.majors?.[index]?.majorIndex
                    }
                    helperText={
                      touched.majors?.[index]?.majorIndex &&
                      errors.majors?.[index]?.majorIndex
                    }
                  />
                </Box>
              );
            })}

            {/* Submit Button */}
            <Box mt={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                Create Course
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const courseSchema = yup.object().shape({
  name: yup.string().min(3).max(50).required('Name is required'),
  image: yup.mixed(),
  collegeIds: yup
    .array()
    .of(yup.string().matches(/^[0-9a-fA-F]{24}$/, 'Invalid collegeId format'))
    .required('At least one College is required'),
  majors: yup
    .array()
    .of(
      yup.object().shape({
        id: yup
          .string()
          .matches(/^[0-9a-fA-F]{24}$/, 'Invalid majorId format')
          .required('Major ID is required'),
        majorIndex: yup.number().required('Major index is required'),
      })
    )
    .required('At least one Major is required'),
});

export default CreateCourseForm;
