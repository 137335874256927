import { Box, Button, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import api from '../../../axios';
const CreateKutlaForm = () => {
  const [image, setImage] = useState(null);

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('link', values.link);
      formData.append('image', values.image);
      const response = await api.post('/kutla', formData);
      resetForm();
      setImage(null);
      toast.success('Kutla created successfully');
    } catch (error) {
      console.error('Error creating kutla:');
      toast.error(error?.response?.data?.msg || 'Failed to create kutla');
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        Create Kutla
      </Typography>

      {/* Form */}
      <Formik
        initialValues={{
          name: '',
          link: '',
          image: null,
        }}
        validationSchema={kutlaSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              margin="normal"
            />

            <TextField
              fullWidth
              variant="filled"
              type="url"
              label="Link"
              name="link"
              value={values.link}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.link && !!errors.link}
              helperText={touched.link && errors.link}
              margin="normal"
            />

            {/* Image Upload */}
            <input
              id="image"
              name="image"
              type="file"
              onChange={(event) => {
                setFieldValue('image', event.currentTarget.files[0]);
                setImage(URL.createObjectURL(event.currentTarget.files[0]));
              }}
            />
            {image && <img src={image} alt="KutlaImage" width="200" />}

            {/* Submit Button */}
            <Box mt={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                Create Kutla
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Validation schema for the kutla form
const kutlaSchema = yup.object().shape({
  name: yup.string().min(3).required('Name is required'),
  link: yup.string().url().required('Link is required'),
  image: yup.mixed().required('Image is required'),
});

export default CreateKutlaForm;
