import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, Visibility } from '@mui/icons-material'; // Removed Edit icon import
import Header from '../../../components/Header';
import { useTheme } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../axios';
import toast from 'react-hot-toast';
import CustomGridToolbar from '../../../components/CustomGridToolbar';
import { useNavigate } from 'react-router-dom';

const Courses = () => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/course/${selectedItemId}`);
      setOpenConfirmationDialog(false);
      await queryClient.refetchQueries('courses');
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setOpenConfirmationDialog(true);
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'image',
      headerName: 'Image',
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt=""
          style={{ width: '40px', height: 'auto' }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteOutline />
          </IconButton>
          <IconButton
            aria-label="view"
            onClick={() => handleView(params.row.id)}
          >
            <Visibility />
          </IconButton>
        </Box>
      ),
    },
  ];

  const navigate = useNavigate();
  const handleView = (id) => {
    navigate(`/courses/${id}`);
  };

  const {
    data: courses,
    isLoading,
    isError,
  } = useQuery('courses', async () => {
    const response = await api.get('/course?limit=1000');
    return response.data.course;
  });

  const {
    data: majors,
    isLoading: majorsLoading,
    isError: majorsError,
  } = useQuery('majors', async () => {
    const response = await api.get('/major?limit=1000');
    return response.data.major;
  });

  if (isLoading || majorsLoading) return <div>Loading...</div>;
  if (isError || majorsError) return <div>Error fetching data</div>;

  return (
    <Box m="20px">
      <Header title="COURSES" subtitle="List of Courses for Future Reference" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
        }}
      >
        <DataGrid
          rows={courses?.map((course) => ({
            id: course._id,
            name: course.name,
            image: course?.image?.secure_url || '',
          }))}
          columns={columns}
          components={{ Toolbar: CustomGridToolbar }}
        />
      </Box>
      <Dialog open={openConfirmationDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Item</DialogTitle>
        <DialogContent>
          <Box>Are you sure you want to remove this item?</Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={{ backgroundColor: theme.palette.primary.main }}
          >
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Courses;
