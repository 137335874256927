import { Routes, Route, Navigate } from 'react-router-dom';
import Kutlas from './scenes/kutlas/kutlasPage';
import LoginForm from './scenes/login';
import OTPLoginForm from './scenes/otp-login';
import Layout from './layout';
import RequireAuth from './components/RequireAuth';
import ResetPassword from './scenes/reset-password';
import { useAuthHeader } from './axios';
import CreateKutlaForm from './scenes/kutlas/createKutla';
import News from './scenes/news/newsPage';
import CreateNewsForm from './scenes/news/createNews';
import Speeches from './scenes/speeches/speechesPage';
import CreateSpeechesForm from './scenes/speeches/createSpeeches';
import Colleges from './scenes/colleges/collegesPage';
import CreateCollegeForm from './scenes/colleges/CreateCollege';
import Majors from './scenes/majors/majorsPage';
import CreateMajorsForm from './scenes/majors/CreateMajors';
import Courses from './scenes/courses/coursesPage';
import CreateCourseForm from './scenes/courses/CreateCourses';
import CoursePage from './scenes/courses/coursePage';

function App() {
  useAuthHeader();
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Protected routes */}
        <Route path="/" element={<Navigate to="/kutlas" />} />
        <Route element={<RequireAuth />}>
          <Route path="/Kutlas" element={<Kutlas />} />
          <Route path="/add-kutla" element={<CreateKutlaForm />} />
          <Route path="/news" element={<News />} />
          <Route path="/add-news" element={<CreateNewsForm />} />
          <Route path="/speeches" element={<Speeches />} />
          <Route path="/add-speeches" element={<CreateSpeechesForm />} />
          <Route path="/colleges" element={<Colleges />} />
          <Route path="/add-colleges" element={<CreateCollegeForm />} />
          <Route path="/majors" element={<Majors />} />
          <Route path="/add-majors" element={<CreateMajorsForm />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:id" element={<CoursePage />} />
          <Route path="/add-courses" element={<CreateCourseForm />} />
        </Route>

        {/* Public routes */}
        <Route path="/auth">
          <Route path="login" element={<LoginForm />} />
          <Route path="otp-login/:email" element={<OTPLoginForm />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>

        {/* Not found */}
        <Route path="*" element={<h1>Page not found</h1>} />
      </Route>
    </Routes>
  );
}

export default App;
